import {
  getLangFromCMS,
  getLangFromDevStaticPath,
  getLangFromStaticPath,
} from '@/api/public/text';
import { customRequestIdleCallback } from '@/utils/requestIdleCallback';
import * as Sentry from '@sentry/react';
import {
  initTrackingObserver,
  postHogInit,
  posthog,
  removeAllFetchCache,
} from '@zenlayer/portal-utils';
import { getLocale, setLangJSON } from '@zenlayer/zen-intl18';
import { useRequest } from 'ahooks';
import { useState } from 'react';

// init evnet tracker observer
initTrackingObserver();

const setLangByCMS = async () => {
  // 开发环境用本地数据合并CMS数据
  const langJSON = await getLangFromCMS();

  if (langJSON) {
    try {
      if (!__PRODUCTION__) {
        const { data } = await getLangFromDevStaticPath();
        langJSON.text = { ...langJSON.text, ...data };
      }
      setLangJSON(langJSON);
    } catch (e) {
      console.error(e);
    }
  }
};
const setLangByLocal = async () => {
  // 线上环境使用本地数据, 本地数据更新需要在CMS发布
  const locale = getLocale();
  const { data } = await getLangFromStaticPath(locale);
  const { self, inherited } = data;
  const langJSON = {
    id: 0,
    domain: locale,
    text: { ...(inherited || {}), ...(self || {}) },
  };
  try {
    setLangJSON(langJSON);
  } catch (e) {
    removeAllFetchCache();
    setLangJSON(langJSON);
  }
};
const setLangByEnv = async (isOnline: boolean) => {
  if (isOnline) {
    await setLangByLocal();
  } else {
    await setLangByCMS();
  }
};

export function getPortalEnv() {
  return localStorage.getItem('PORTAL_ENV');
}

export const getRuntimeInfo = () => {
  return window._zen_env_ || {};
};

const initEventTracker = () => {
  customRequestIdleCallback(() => {
    postHogInit('phc_FxlFsxavQymtBkGXyGGU3VBf0Rs0yiK1jVdyr9edB2y');
    Sentry.init({
      dsn: 'https://f09112d06d2633f85165a8e10d616cbf@o4507537049452544.ingest.us.sentry.io/4507537050894336',
      integrations: [
        posthog.sentryIntegration({
          organization: 'zenlayer',
          projectId: 1,
          // severityAllowList: ['error'],
        }),
        Sentry.browserTracingIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/[^/]+\/api/],
    });
  });
};

export default function useRuntimeInfo() {
  const [ready, setReady] = useState(false);
  const data = getRuntimeInfo();

  useRequest(async () => {
    let env;
    if (__PRODUCTION__) {
      env = data.online ? 'ONLINE' : 'TEST';
    } else {
      env = 'DEVELOPMENT';
    }

    if (env === 'ONLINE') {
      // 在生产环境引入
      initEventTracker();
    }
    localStorage.setItem('PORTAL_ENV', env);
    await setLangByEnv(env === 'ONLINE');
    setReady(true);
  });

  return {
    ready,
  };
}
